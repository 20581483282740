<template>
    <div style="background: #fff;margin-top:-16px;min-height: 300px;padding: 20px">
        <a-descriptions title="" layout="vertical">
            <!-- 货堆 产品预算  宅配 产品预算/份-->
            <a-descriptions-item label="产品预算/份" v-if="needOrderDetail.subType == 102 || needOrderDetail.subType == 103">{{ needOrderDetail.marketPrice.toFixed(2) }}元</a-descriptions-item>
            <a-descriptions-item label="采购数量" v-if="needOrderDetail.subType == 102 || needOrderDetail.subType == 103">{{ needOrderDetail.buyCount }}</a-descriptions-item>
            <a-descriptions-item label="产品预算" v-if="needOrderDetail.subType == 101">{{ needOrderDetail.marketPrice.toFixed(2) }}元</a-descriptions-item>
            <!-- <a-descriptions-item label="合计预期产品成本">{{ needOrderDetail.costPrice }}</a-descriptions-item> -->
            <a-descriptions-item label="期望完成时间" v-if="needOrderDetail.planExceptFinishTime">{{ needOrderDetail.planExceptFinishTime }}</a-descriptions-item>
            <a-descriptions-item label="需求描述">{{ needOrderDetail.info }}</a-descriptions-item>
            <a-descriptions-item label="产品具体需求附件">
                <a v-if="needOrderDetail.infoFileUrl" @click="openFile">查看文件</a>
                <span v-else>-</span>
            </a-descriptions-item>
            
        </a-descriptions>
        <a-divider />
        

        <div v-if="needOrderDetail.subType != 103">
            <div v-if="needOrderDetail.subType == 101">
                <addressTable  :type="1" :needOrderDetail="needOrderDetail" :needId="this.$route.query.id" ref="addressRef" :isEdit="false"></addressTable>
            </div>
            <!-- 宅配 -->
            <div v-if="needOrderDetail.subType == 102">
                <addressTable  :type="2" :needOrderDetail="needOrderDetail" :needId="this.$route.query.id" ref="addressRef" :isEdit="false"></addressTable>
            </div>
            <a-divider />
        </div>

        <p>备注</p>
        <span class="remarks">{{ needOrderDetail.remark }}</span>
    </div>
</template>

<script>
import addressTable from '../../components/addressTable'
export default {
    props: {
        needOrderDetail: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            // needOrderDetail:{}
        }
    },
    mounted() {
        // if(this.needOrderDetail) {
            if(this.needOrderDetail.subType != 103) {
                this.$refs.addressRef.initAddress(this.needOrderDetail);
                this.$refs.addressRef.getAddressList();
            }
            
        // }
    },
    components: { addressTable },
    methods:{
        // sendAddressType(e) {
        //     this.addressType = e;
        //     console.log(e, 'e')
        // },
        openFile(){
            window.open(this.needOrderDetail.infoFileUrl)
        }
    }

}
</script>