<template>
    <div style="min-height: calc(100vh - 88px);background: #F3F5F8;padding: 20px;">
        <p class="title">需求单</p>
        <div class="card">
            <div style="display:flex;align-items: center;margin-bottom: 24px;">
                <img style="width:64px;height: 64px;border-radius: 8px" src="https://qiniu.youjiamall.cn/crmNeed01.png"
                    alt="">
                <p style="font-family: PingFangSC, PingFang SC;font-weight: 600;font-size: 16px;color: #3A3F63;margin-left: 10px;">
                    基础信息</p>
            </div>
            <a-descriptions title="" layout="vertical">
                <a-descriptions-item label="创建人">{{ needInfo.createByDesc }}</a-descriptions-item>
                <a-descriptions-item label="客户名称">{{ needInfo.customerDesc }}</a-descriptions-item>
                <a-descriptions-item label="项目等级">{{ needInfo.projectTypeDesc }}</a-descriptions-item>
                <a-descriptions-item label="需求名称">{{ needInfo.name }}</a-descriptions-item>
                <!-- <a-descriptions-item label="产品预算">{{ needInfo.marketPrice }}</a-descriptions-item> -->
                <a-descriptions-item label="合计产品预算">{{
                    needInfo.marketPrice.toFixed(2)
                }}元</a-descriptions-item>
                <!-- <a-descriptions-item label="合计产品预算" v-if="needOrderDetail.subType == 102">{{
                    needInfo.marketPrice.toFixed(2)
                }}元</a-descriptions-item> -->
                <!-- <a-descriptions-item label="预计产品成本">{{ needInfo.costPrice }}</a-descriptions-item> -->
            </a-descriptions>
        </div>



        <div style="margin-top: 20px">
            <div style="display: flex;align-items: center;">
                <p class="title">
                    <!-- <a-tabs v-model="activeKey" type="card">
                        <a-tab-pane :key="item.id" :tab="item.subTypeDesc" v-for="item in detailsList" >
                        </a-tab-pane>
                    </a-tabs> -->
                    {{ needOrderDetail.subTypeDesc }}
                    <span v-if="needOrderDetail.cancelReason" style="font-size: 14px;color:red;">
                        取消原因：{{ needOrderDetail.cancelReason }}
                    </span>
                </p>
            </div>

            <div style="background:#fff;margin-bottom:20px;border-radius: 10px">
                <div style="display: flex;height: 60px;align-items: center;padding:0 20px;">
                    <p style="font-size: 15px;color:#101010;">
                        需求状态：<span style="color:red">{{ needOrderDetail.statusDesc }}</span>
                        <span v-if="needOrderDetail.reason && needOrderDetail.status == 40" style="margin-left:10px;color:red">（驳回原因：{{ needOrderDetail.reason }}）</span>
                    </p>
                    <a-button type="danger" v-if="needOrderDetail.status != 120" ghost @click="handleCancel()"
                        style="margin-left:auto;margin-right: 20px;">取消需求</a-button>
                </div>
                <a-table :columns="columns" :dataSource="dataSource" :pagination="false">
                    <template slot="createTime" slot-scope="record,text">
                        {{ formatDateMin(text.createTime) }}
                    </template>
                </a-table>
                <div class="page-box">
                    <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
                        @change="handlePageChange" />
                </div>
            </div>
            <a-tabs v-model="activeKey" type="card">
                <a-tab-pane :key="1" tab="需求信息">
                    <step1 :needOrderDetail="needOrderDetail"></step1>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="方案信息">
                    <step2 :needOrderDetail="needOrderDetail" @reload="getInfo" :needId="needId"></step2>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="中标信息">
                    <step3 ref="step3" :needOrderDetail="needOrderDetail" @reload="getInfo" :needId="needId"></step3>
                </a-tab-pane>
            </a-tabs>
        </div>
        <messageLog></messageLog>
        <a-modal title="取消原因" :visible="cancelOpen" :maskClosable="false" @ok="cancelNeed" :width="500"
            @cancel="cancelOpen = false; reason = null" :z-index="100">
            <a-textarea v-model="resonNeed"></a-textarea>
        </a-modal>

    </div>

</template>
<script>
import { request } from '@/utils/request'
import { EventBus } from '@/utils/event-bus.js';

import moment from 'moment';
import messageLog from '../components/messageLog.vue';
import step1 from './components/step1.vue'
import step2 from './components/step2.vue'
import step3 from './components/step3.vue'
export default {
    components: { messageLog, step1, step2, step3 },
    data() {
        return {
            activeKey: 1,
            value: '',
            moment,
            needInfo: {
            },
            needOrderDetail: {
                // subTypeDesc:"货堆",
                // addressType: 1,
                // marketPrice: 100,
                // costPrice: 2,
                // planExceptFinishTime:"2024-10-30",
                // planType:1,//1采购 2销售
                // subType:101 //  101货堆 102宅配
            },
            needId: "",
            cancelOpen: false,
            resonNeed: "",
            queryData: {
                page: 0,
                size: 2,
                // type:2
            },
            dataSource: [],
            total: 0,
            columns: [
                { title: '信息', dataIndex: 'info', align: 'center' },
                { title: '操作人', dataIndex: 'createByDesc', align: 'center' },
                // { title: '类型', dataIndex: 'operateTypeDesc', align: 'center' },
                { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' }, },
            ],
            detailsList:[]
        }
    },
    mounted() {
        this.getInfo()
        this.getData()
        this.getDetailsList();
    },
    methods: {
        getDetailsList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/detailList/' + this.$route.query.needId, 'post', {}).then(res => {
                if (res.data.code == 200) {
                    console.log(res,'resssss')
                    this.detailsList = res.data.data;
                }
            })
        },
        getData() {
            this.queryData.id = this.$route.query.id;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderLog/list', 'post', this.queryData).then(res => {
                let data = res.data.data;
                this.tabLoading = false;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.getData()
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
        handleCancel() {
            this.resonNeed = ""
            this.cancelOpen = true;
        },
        cancelNeed() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/cancel', 'post', {
                id: this.$route.query.id,
                reason: this.resonNeed,
            }).then(res => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.$message.success('操作成功！');
                    this.cancelOpen = false;
                    EventBus.$emit('itemRemoved');
                    this.$router.replace('/sfa_demandList/sfa_demand_list')
                } else {
                    this.$message.error(res.data.message);
                    this.loading = false;
                }
            })
        },
        getInfo() {
            let id = this.$route.query.id
            this.needId = this.$route.query.id
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/' + this.$route.query.needId, 'post', {}).then(res => {
                this.needInfo = res.data.data;
            })
            // 需求详情 子需求单id
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderDetail/' + id, 'post', {}).then(res => {
                this.needOrderDetail = res.data.data;
                console.log(res, 'res')
            })

        },
        handleChange(e) {
            this.value = e.target.value;
        },
    }
}
</script>

<style scoped>
.title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #010101;
    margin-bottom: 20px;
}

.card {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
}

::v-deep .ant-descriptions-item-content {
    font-family: PingFangSC, PingFang SC;
    /* font-weight: 500; */
    font-size: 16px;
    color: #000000;
}

::v-deep .ant-descriptions-item-label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #84818A;
}

.card-box {
    border: 1px solid #f0f0f0;
    margin-top: 20px;
}

::v-deep .ant-card-head {
    background: #FAFAFA;
    padding: 0 20px;
}

.remarks {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}


::v-deep .ant-table-thead>tr>th {
    background: #fff !important;
}

p {
    margin: 0;
    padding: 0;
}

.card-tit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-left: 10px;
}

.card-tit-img {
    width: 38px;
    height: 38px;
    border-radius: 100%
}


.card-box-list {
    padding: 24px 0;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E8E8E8;
    padding: 26px;
    /* width: 288px; */
}

.card-box-list-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
}

.card-box-list-title img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 16px;
}

.card-box-list-mintit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin: 12px 0;
    display: inline-block;
}


.card-box-list-main {
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
}

.bigtext {
    font-family: HelveticaNeue, HelveticaNeue;
    font-weight: 500;
    font-size: 24px;
    color: #010101;
}

.page-box {
    display: flex;
    justify-content: right;
    margin-top: 10px;
    padding: 15px 10px;
}
</style>