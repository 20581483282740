<template>
    <div class="card" style="margin-top: 20px;min-height: 200px;">
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="留言">
                <div>
                    <div slot="content">
                        <a-form-item>
                            <a-textarea :rows="2" v-model="info" placeholder="留言备注" />
                        </a-form-item>
                        <a-form-item>
                            <a-button :loading="submitting" type="primary" @click="handleSubmit">
                                发送
                            </a-button>
                        </a-form-item>
                    </div>
                    <a-list v-if="comments.length" :data-source="comments"
                        :header="`${comments.length} ${comments.length > 1 ? '条留言' : '条留言'}`" item-layout="horizontal">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <a-comment :author="item.createByDesc" :content="item.info" :datetime="item.createTime" />
                        </a-list-item>
                    </a-list>
                    <a-comment>

                    </a-comment>
                </div>
            </a-tab-pane>
            <!-- <a-tab-pane key="2" tab="更改日志">
                <div>
                    <a-table :columns="columns" :dataSource="dataSource" :pagination="false">
                        <template slot="createTime" slot-scope="record,text">
                            {{ formatDateMin(text.createTime) }}
                        </template>
                    </a-table>
                    <div class="page-box">
                        <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
                            @change="handlePageChange" />
                    </div>
                </div>
            </a-tab-pane> -->
        </a-tabs>
    </div>
</template>

<script>
import moment from 'moment';
import { request } from '@/utils/request'

export default {
    data() {
        return {
            comments: [],
            submitting: false,
            moment,
            queryData: {
                page: 0,
                size: 10,
                // type:2
            },
            dataSource: [],
            total: 0,
            columns: [
            { title: '操作人', dataIndex: 'createByDesc', align: 'center' },
                { title: '信息', dataIndex: 'info', align: 'center' },
                // { title: '类型', dataIndex: 'operateTypeDesc', align: 'center' },
                { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' }, },
            ],
            info: ""
        }
    },
    mounted() {
        this.getMessage()
        this.getData()
    },
    methods: {
        callback(e) {
            console.log(e, 'e')
        },

        handleSubmit() {
            if (!this.info) {
                this.$message.error('请输入内容')
                return;
            }
            this.submitting = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderMsg/create', 'post', { id: this.$route.query.id, info: this.info }).then(res => {
                console.log(res, 'resmsg')
                if (res.data.code == 200) {
                    this.info = ""
                    this.getMessage()
                }
            })
            this.submitting = false;
        },

        getMessage() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderMsg/list', 'post', { page: 0, size: 1000, id: this.$route.query.id }).then(res => {
                let data = res.data.data;
                this.comments = data.data;
                this.comments.forEach(item => {
                    item.createTime = this.formatDateMin(item.createTime)
                })
            })
        },
        getData() {
            this.queryData.id = this.$route.query.id;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderLog/list', 'post', this.queryData).then(res => {
                let data = res.data.data;
                this.tabLoading = false;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.getData()
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
    }
}
</script>
<style scoped>
.page-box {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

::v-deep .ant-list-header {
    font-weight: bolder !important;
    font-size: 16px;
}
</style>